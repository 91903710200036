html,
body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
}

.MuiAccordionSummary-root.MuiAccordionSummary-root {
  padding: 0px 24px;
}

.MuiTab-root {
  font-size: 0.8125rem;
}

@media (min-width: 600px) {
  div.MuiContainer-root {
    padding-left: 32px;
    padding-right: 32px;
  }

  .MuiTab-root {
    font-size: 0.8125rem;
  }
}
